import React, { useState, useRef } from "react"
import styled from "@emotion/styled"
import { SerializedStyles } from "@emotion/core"

const Slider = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 814px) {
    overflow: hidden;
  }
`

const Slides = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  overflow-x: auto;
  text-align: center;

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE and Edge */
  -ms-overflow-style: none;

  @media (max-width: 1275px) {
    justify-content: normal;
  }
  @media (min-width: 814px) {
    width: 100%;
    height: 300px;
  }
  @media (max-width: 814px) {
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    width: 326px;
  }
`

const SlideWrapper = styled.div``

const Dots = styled.div`
  display: flex;
  justify-content: space-between;
  width: 42px;
  padding: 1.5rem 0;
  @media (min-width: 814px) {
    display: none;
  }
`

const Dot = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 10px;
  background-color: #c7c7c7;
  &.active {
    background-color: #838383;
  }
`

const DotDark = styled(Dot)`
  background-color: #838383;
  &.active {
    background-color: #c7c7c7;
  }
`

type Props = {
  children: React.ReactElement
  onClick?: (selectedItem: any) => void
  onScroll?: (selectedItem: any) => void
  dataSlides: any
  dark?: boolean
  slidesCSS?: SerializedStyles
  dotsCSS?: SerializedStyles
}

const CustomSlider = ({
  children,
  onClick,
  onScroll,
  dataSlides,
  dark = false,
  slidesCSS = undefined,
  dotsCSS = undefined,
}: Props) => {
  const [activeTabIdx, setActiveTabIdx] = useState(0)
  const refSlides = dataSlides.map(() => useRef(null))
  let slider = useRef(null)

  const scrollHandler = () => {
    if (slider && slider.current) {
      const castedSlider = slider as React.MutableRefObject<any>
      const sliderXValue = castedSlider.current.getBoundingClientRect().left

      refSlides.forEach((ref: any, idx: number) => {
        if (ref && ref.current) {
          const slide = ref as React.MutableRefObject<any>
          const valueSlide = slide.current.getBoundingClientRect().left

          if (
            sliderXValue - 2 <= valueSlide &&
            valueSlide <= sliderXValue + 2
          ) {
            setActiveTabIdx(idx)
            if (onScroll) onScroll(dataSlides[idx])
          }
        }
      })
    }
  }

  const UseDots = dark ? DotDark : Dot

  return (
    <Slider>
      <Slides ref={slider} onScroll={scrollHandler} css={slidesCSS}>
        {dataSlides.map((dataSlide: any, idx: number) => (
          <SlideWrapper key={`slide-${idx}`} ref={refSlides[idx]}>
            {React.cloneElement(children, {
              active: activeTabIdx === idx,
              data: dataSlide,
              onClick: () => {
                setActiveTabIdx(idx)
                if (onClick) onClick(dataSlide)
              },
            })}
          </SlideWrapper>
        ))}
      </Slides>

      <Dots css={dotsCSS}>
        {dataSlides.map((_: any, idx: number) => (
          <UseDots
            className={activeTabIdx === idx ? "active" : undefined}
            key={`dot-${idx}`}
          />
        ))}
      </Dots>
    </Slider>
  )
}

export default CustomSlider
